import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Iconify, Label } from "../../components";
import { capitalize } from "lodash";
import {
  Box,
  Tab,
  Tabs,
  Stack,
  Skeleton,
  styled,
  Typography,
  Tooltip,
  Card,
  useTheme,
  Button,
} from "@mui/material";
import back from "../../assets/img/icons/back.svg";
import profile from "../../assets/img/team/vanilla-profile.jpg";
import Image from "../../components/image/Image";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import {
  EXPORT_FEE_REPORT,
  REMITTANCE_INCOMING,
  REMITTANCE_OUTGOING,
  SUPPLIER,
  SUPPLIER_BUS,
} from "../../ApiUrl";
import usePageTitle from "../../hooks/usePageTitle";
import { BusinessDetails, Transactions } from "./components";
import ProgrammedLiens from "./components/programmedLiens";
import { CustomAvatar } from "../../components/customAvatar";
import signinBg from "../../assets/img/signinBg.png";
import UpdateSupplierStatusModal from "./components/updateSupplierStatusModal";
import Fees from "./components/fees";
import { statusColors } from "../../utils/common";
import KycKybStatusHistory from "./components/kycKybStatusHistory/kycKybStatusHistory";
import CustomerTransactionStatement from "./components/customerTransactionStatement/customerTransactionStatement";
import CustomerAllTransactions from "./components/customerAllTransactions/customerAllTransactions";
import SupplierDocumentDetails from "./supplierDocumentDetail";

const StyledRoot = styled("div")(({ theme }) => ({
  "&:before": {
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    // backgroundColor: alpha('#000000', 1),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const StyledInfo = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(5),
  },
}));

const SupplierDetails = ({ type }) => {
  const [currentTab, setCurrentTab] = useState("business details");
  const [supplier, setSupplier] = useState(null);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();
  // const params = useParams();
  const query = new URLSearchParams(location.search);

  const filters = Object.fromEntries(query);
  const { id } = filters;
  const theme = useTheme();

  usePageTitle("Customer Details");

  const toggleStatusModal = () => {
    console.log("owncowieociwnoec");
    setShowStatusModal((state) => !state);
  };

  const updateStatusClickHandler = (transaction) => {
    toggleStatusModal();
  };

  const updateClickHandler = async (status) => {
    const url =
      status === "active"
        ? `${SUPPLIER}${id}/activate/`
        : `${SUPPLIER}${id}/deactivate/`;
    try {
      setStatusUpdateLoading(true);
      const response = await merchantApi.post(url);
      toast.success("Customer Status Updated");
      setStatusUpdateLoading(false);
      setShowStatusModal(false);
      getClients();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      setStatusUpdateLoading(false);
    }
  };

  async function getClients() {
    try {
      setLoading(true);
      const response = await merchantApi.get(`${SUPPLIER_BUS}${id}/`);
      console.log(response);
      setClient(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setLoading(false);
    }
  }

  useEffect(() => {
    getClients();
    if (location?.state?.isEdit) {
      setCurrentTab("documents");
    }
  }, [id, location]);

  const PREVIEW_TABS = [
    {
      value: "business details",
      icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: <BusinessDetails client={client} getClients={getClients} />,
    },
    {
      value: "documents",
      icon: <Iconify icon={"solar:document-bold"} width={20} height={20} />,
      component: (
        <SupplierDocumentDetails client={client} getClient={getClients} />
      ),
    },
    {
      value: "Transactions",
      icon: <Iconify icon={"solar:bill-list-broken"} width={20} height={20} />,
      component: <CustomerAllTransactions client={client} />,
    },
    {
      value: "Transaction Statement",
      icon: <Iconify icon={"solar:bill-list-broken"} width={20} height={20} />,
      component: <CustomerTransactionStatement client={client} />,
    },
    {
      value: "KYB/KYC Status History",
      icon: (
        <Iconify icon={"material-symbols:history"} width={20} height={20} />
      ),
      component: (
        <KycKybStatusHistory client={client} getClients={getClients} />
      ),
    },
  ];

  const backClickHandler = () => {
    history.goBack();
  };

  const handleExportExcel = async () => {
    try {
      const response = await merchantApi.get(`${SUPPLIER_BUS}${id}/export/`, {
        responseType: "blob",
      });
      console.log("blob response", response);
      // Create a temporary URL for the blob
      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobURL = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = blobURL;

      // Set the downloaded file name
      link.download = "customer-business-detail.pdf";

      // Append to the body, trigger click, and remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the object URL to free up memory
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box sx={{ padding: "24px " }}>
      {loading ? (
        <Skeleton variant="rounded" animation={"wave"} height={1500} />
      ) : (
        <>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <span onClick={backClickHandler} className="back mb-4">
              <img src={back} alt="back" />
              <span>Back</span>
            </span>
          </Stack>
          <Card
            sx={{
              mb: 3,
              height: 200,
              position: "relative",
            }}
          >
            <StyledRoot>
              <StyledInfo>
                <Stack sx={{ position: "relative", width: "fit-content" }}>
                  <CustomAvatar
                    // src={profile}
                    alt={client?.business_name}
                    name={client?.business_name || "Supplier Name"}
                    sx={{
                      mx: "auto",
                      borderWidth: 2,
                      borderStyle: "solid",
                      borderColor: "common.white",
                      width: { xs: 80, md: 128 },
                      height: { xs: 80, md: 128 },
                      fontSize: "40px",
                    }}
                  />
                </Stack>
                <Box
                  sx={{
                    ml: { md: 3 },
                    mt: { xs: 1, md: 0 },
                    color: "common.white",
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  <Stack
                    direction={"column"}
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                    spacing={1}
                  >
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <Typography variant="h4" sx={{ color: "white" }}>
                        {`${capitalize(client?.business_name || "Supplier")}`}{" "}
                      </Typography>
                      <Label
                        variant={"outlined"}
                        color={
                          client?.status === "active"
                            ? "complete"
                            : "application submitted"
                        }
                        // backgroundColor={"complete"}
                        sx={{ textTransform: "capitalize", fontSize: "14px" }}
                      >
                        {client?.status}
                      </Label>
                    </Stack>
                    <Stack direction={"row"} gap={1}>
                      <Button
                        variant="contained" // Choose between "contained", "outlined", or "text"
                        color="secondary"
                        type="button"
                        size="medium"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          fontSize: "14px",
                        }}
                        onClick={toggleStatusModal}
                      >
                        <Iconify
                          icon="icon-park-solid:update-rotation"
                          width={22}
                        />
                        Update Customer Status
                      </Button>
                      <Button
                        variant="contained" // Choose between "contained", "outlined", or "text"
                        //   color="secondary"
                        type="button"
                        size="medium"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          fontSize: "14px",
                        }}
                        onClick={() => handleExportExcel()}
                      >
                        <Iconify icon="material-symbols:download" width={22} />
                        Download Customer Details
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </StyledInfo>

              <Image
                alt="cover"
                src={signinBg}
                sx={{
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  position: "absolute",
                  objectFit: "cover",
                }}
              />
            </StyledRoot>
          </Card>

          <Stack
            justifyContent={"space-between"}
            alignItems={{ md: "center", xs: "start" }}
            direction={{ md: "row", xs: "column" }}
            spacing={2}
          >
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              sx={{
                background: theme.palette.common.lightAccentColor,
                width: "100%",
                borderRadius: "7px",
                "& .MuiTab-labelIcon": {
                  color: theme.palette.common.accentColor,
                },
              }}
            >
              {PREVIEW_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalize(tab.value)}
                  iconPosition="start"
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Stack>
          {PREVIEW_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </>
      )}
      {showStatusModal && (
        <UpdateSupplierStatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={(status) => {
            console.log("upsate status", status);
            updateClickHandler(status);
          }}
          statusUpdateLoading={statusUpdateLoading}
          status={client?.status}
        />
      )}
    </Box>
  );
};

export default SupplierDetails;
