import { makeStyles } from "@material-ui/core";
import { capitalize } from "lodash";
import documentImg from "../assets/img/icons/document.png";
import { zonedTimeToUtc, formatInTimeZone } from "date-fns-tz";
import { format as Format } from "date-fns";
import { merchantApi } from "../redux/api/apiConfig";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  format,
  isAfter,
} from "date-fns";

const mime = require("mime-types");

export const _socials = [
  {
    value: "copy",
    name: "Copy",
    icon: "eva:copy-outline",
    color: "#637381",
    path: "https://www.instagram.com/caitlyn.kerluke",
  },
  {
    value: "email",
    name: "Email",
    icon: "eva:email-fill",
    color: "#3E9FE8",
    path: "https://www.facebook.com/caitlyn.kerluke",
  },

  {
    value: "whatsapp",
    name: "WhatsApp",
    icon: "logos:whatsapp-icon",
    color: "#00AAEC",
    path: "https://www.twitter.com/caitlyn.kerluke",
  },
];

export const allowedMediaTypes = {
  "image/*": [],
  "video/*": [],
  "application/*": [
    ".pdf",
    ".msword",
    ".xlsx",
    ".xls",
    ".rtf",
    ".ppt",
    ".pptx",
    ".doc",
    ".docx",
  ],
  "text/*": [],
};

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const numberCommaFormatter = (value) =>
  value.toLocaleString(undefined, { maximumFractionDigits: 8 });

export const percentCalculate = (current, prev) =>
  current > 0 ? ((current - prev) / current) * 100.0 : 0;

export const formatCurrency = (
  value = 0,
  decimal = 2,
  symbol = "",
  prefix = false
) => {
  let trimmedValue = Intl.NumberFormat("en-US").format(
    Number(Number(value).toFixed(decimal))
  );
  return prefix ? `${symbol} ${trimmedValue}` : `${trimmedValue} ${symbol}`;
};

export const useStyles = makeStyles(() => ({
  textField: {
    // height: '20px',

    // border: '#0B0523 1px solid',
    borderRadius: "8px",
  },
  input: {
    color: "black",
    backgroundColor: "white",
  },
}));

export const textFieldLabelProps = {
  style: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "gray",
  },
};

export const notificationTypesPastTenses = {
  refund: "Refunded",
  fund: "Funded",
  withdraw: "Withdrew",
  receipt: "Received",
};

export const statusColors = {
  colors: {
    "application submitted": "#B78103",
    pending: "#003bbbdc",
    complete: "#229A16",
    rejected: "#B72136",
  },
  bgColors: {
    "application submitted": "#ffc10729",
    pending: "#afe2ffdc",
    complete: "#54d62c29",
    rejected: "#ff484229",
  },
};

export const tableCellStyle = {
  color: "black",
  fontWeight: "400",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const tableStyle = {
  borderRadius: "8px",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
};

export const capital = (word) => {
  let letters = word.split("");
  let capitalLetters = letters.map((letter) => capitalize(letter));
  return capitalLetters.join("");
};

export const transactionStatusColors = {
  pending: "application submitted",
  processed: "complete",
  reject_request: "application submitted",
  expired: "rejected",
  stb_reject: "rejected",
  failed: "rejected",
  payout_done: "complete",
  reject_failed: "rejected",
  reject_complete: "complete",
  draft: "pending",
  redeem_failed: "rejected",
  redeem_complete: "complete",
  Active: "complete",
  Expired: "rejected",
  open: "rejected",
  closed: "complete",
  P1: "rejected",
  P2: "application submitted",
  P3: "pending",
};

export const customerStatusLabel = {
  active: "active",
  draft: "draft",
};

export const paymentStatus = [
  {
    name: "Unsettled",
    value: "unsettled",
  },
  {
    value: "partial_settled",
    name: "Partial Settled",
  },
  {
    value: "fully_settled",
    name: "Fully Settled",
  },
  {
    value: "under_claim_request",
    name: "Under Claim Request",
  },
];

export const getThumbnail = (url) => {
  console.log(mime.lookup(url), url);
  if (url) {
    return mime.lookup(url)?.split("/")[0] === "image" ? url : documentImg;
  } else {
    return documentImg;
  }
};

export const formatDate = (date, format) => {
  try {
    return date ? Format(new Date(date), format) : null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const rowsPerPageOptions = [7, 10, 20, 50, 100];

export const formatWithoutTimezone = (dateTimeString, format) => {
  // console.log(dateTimeString, "pocno2enowcknwkec");
  let datetime = dateTimeString;
  if (datetime?.includes("+")) {
    datetime = datetime?.split("+")[0];
  } else if (datetime.at(-6) === "-") {
    // Since there can be multiple "-" in the datetime string, using plain split[0] will just return the year
    datetime = datetime.slice(0, -6);
  }
  let zRemoved = datetime?.replace("Z", "");
  // console.log(dateTimeString, datetime, zRemoved);
  return formatDate(zRemoved, format);
};

export const urlRegex =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const todayDate = Format(new Date(), "MM/dd/yyyy");
export const minDate = Format(new Date("01/01/1900"), "MM/dd/yyyy");
export const disablePaymentStatuses = [
  "receiver_reject",
  "sender_reject",
  "stb_reject",
  "reject_complete",
];
export const accountTypes = [
  { name: "Master Account", id: 1 },
  { name: "Sub Account", id: 2 },
];

//function to check permission
export const hasPermission = (rolesArray, permissionsObj) => {
  // Get the role object from the permissions
  const rolesPermissions = permissionsObj?.visible?.role;

  const checkPermission = rolesArray?.some(
    (role) => rolesPermissions[role] === true
  );

  return checkPermission;
};

// Function to compare two arrays of objects
export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  return arr1.every((obj1, index) => {
    const obj2 = arr2[index];
    // Assuming you're comparing objects with id and name keys
    return (
      obj1.attachment === obj2.attachment &&
      obj1.document_name === obj2.document_name
    );
  });
};

const transform = (input) =>
  input
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

// Helper function to parse errors
export const parseErrorsWithFieldNames = (errors) => {
  const messages = [];

  for (const key in errors) {
    if (Array.isArray(errors[key])) {
      // Field-specific errors (e.g., { username: ["This field is required."] })
      errors[key].forEach((message) => {
        messages.push({ field: transform(key), message });
      });
    } else if (typeof errors[key] === "object") {
      // Nested errors (e.g., { user: { email: ["Invalid email"] } })
      const nestedMessages = parseErrorsWithFieldNames(errors[key]);
      nestedMessages.forEach((nestedError) => {
        messages.push({
          field: `${key}.${nestedError?.field}`,
          message: nestedError?.message,
        });
      });
    } else if (typeof errors[key] === "string") {
      // String errors (less common)
      messages.push({ field: key, message: errors[key] });
    }
  }

  return messages;
};

// Common error handler function
export const handleApiErrors = (error) => {
  let errorMessages = [{ field: null, message: "An unknown error occurred." }];

  if (error.response) {
    // Error response from the server
    const { status, data } = error.response;

    if (data) {
      if (data?.detail || data?.message) {
        // Handles { detail: "Error message" }
        errorMessages = [
          { field: null, message: data?.detail || data?.message },
        ];
      } else if (
        data?.non_field_errors &&
        Array.isArray(data?.non_field_errors)
      ) {
        // Handles { non_field_errors: ["Error message"] }
        errorMessages = data?.non_field_errors.map((message) => ({
          field: null,
          message,
        }));
      } else if (Array.isArray(data)) {
        // Handles Error of type array
        errorMessages = data?.map((message) => ({ field: null, message }));
      } else if (typeof data === "object") {
        // Handles field-specific or nested errors
        errorMessages = parseErrorsWithFieldNames(data);
      }
    } else {
      // If no data is returned but there's a status code
      if (status === 403) {
        errorMessages = [
          { field: null, message: "Forbidden: Access is denied." },
        ];
      } else if (status === 500) {
        errorMessages = [
          {
            field: null,
            message: "Internal Server Error. Please try again later.",
          },
        ];
      } else {
        errorMessages = [
          {
            field: null,
            message: `Unexpected error occurred. Status code: ${status}`,
          },
        ];
      }
    }
  } else if (error?.request) {
    // Network errors or no response received
    errorMessages = [
      {
        field: null,
        message: "No response from the server. Please check your connection.",
      },
    ];
  } else if (error?.message) {
    // JavaScript Error object
    errorMessages = [{ field: null, message: error?.message }];
  } else if (typeof error === "string") {
    // Non-object errors
    errorMessages = [{ field: null, message: error }];
  }

  return errorMessages;
};

//Searchable Select Fetch Function Call
export const searchableSelectFetchOptions = async (
  url,
  query,
  additionalParams,
  customReturn
) => {
  const apiUrl = query ? `${url}?search=${query}` : `${url}`; // No search parameter when query is empty

  const response = await merchantApi.get(apiUrl, {
    params: {
      ...additionalParams,
    },
  });
  return customReturn
    ? customReturn(response)
    : response?.data?.results?.map((item) => ({
        label: item?.business_name, // Adjust based on your API response
        value: item?.id,
      }));
};

export const getDateRange = (timePeriod) => {
  const now = new Date();

  const adjustEndDate = (endDate) => (isAfter(endDate, now) ? now : endDate);

  switch (timePeriod) {
    case "week": {
      const fromDate = startOfWeek(now);
      const toDate = adjustEndDate(endOfWeek(now));
      return { fromDate, toDate };
    }
    case "month": {
      const fromDate = startOfMonth(now);
      const toDate = adjustEndDate(endOfMonth(now));
      return { fromDate, toDate };
    }
    case "quarter": {
      const fromDate = startOfQuarter(now);
      const toDate = adjustEndDate(endOfQuarter(now));
      return { fromDate, toDate };
    }
    case "year": {
      const fromDate = startOfYear(now);
      const toDate = adjustEndDate(endOfYear(now));
      return { fromDate, toDate };
    }
    default:
      return { fromDate: null, toDate: null };
  }
};

export const handleExportExcel = async (params, url, fileName) => {
  try {
    const response = await merchantApi.get(url, {
      responseType: "blob",
      params: {
        ...params,
      },
    });
    console.log("blob response", response);
    // Create a temporary URL for the blob
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const blobURL = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = blobURL;

    // Set the downloaded file name
    link.download = `${fileName || "exported-excel"}.xlsx`;

    // Append to the body, trigger click, and remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(blobURL);
  } catch (error) {
    console.log("error", error);
  }
};
