import React, { useEffect, useState } from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent } from "../../../../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../../../../redux/api/apiConfig";
import {
  CUSTOMER_FEE_GROUP,
  FEE,
  MONTHLY_FEE,
  TRANSACTION_TYPE_FEE,
} from "../../../../../ApiUrl";
import SearchableSelectField from "../../../../../components/searchableSelectField";
import { formatDate, handleApiErrors } from "../../../../../utils/common";
import { DatePicker } from "@mui/x-date-pickers";

const validationSchema = yup.object({
  amount_from: yup
    .number("Enter Amount From")
    .min(0, "Please enter Amount From")
    .required("Amount From is required!"),
  amount_to: yup
    .number("Enter Amount To")
    .min(0, "Please enter Amount To")
    .required("Amount To is required!"),
  customer_fee_percentage: yup
    .number()
    .min(0, "Fee Percentage cannot be negative")
    .max(100, "Fee Percentage cannot be more than 100%")
    .test(
      "is-decimal",
      "Fee Percentage must have up to 4 decimal places",
      (value) => String(value).match(/^\d+(\.\d{1,4})?$/)
    ),
  // .test(
  //   "either-percentage-or-fixed",
  //   "Either Fee Percentage or Fee Fixed is required",
  //   function (value) {
  //     const { customer_fee_fixed } = this.parent;
  //     return value !== undefined || customer_fee_fixed !== undefined;
  //   }
  // ),
  customer_fee_fixed: yup.number().min(0, "Fee Fixed cannot be negative"),
  // .test(
  //   "either-percentage-or-fixed",
  //   "Either Fee Percentage or Fee Fixed is required",
  //   function (value) {
  //     const { customer_fee_percentage } = this.parent;
  //     return value !== undefined || customer_fee_percentage !== undefined;
  //   }
  // ),
  group: yup.mixed().required("Group is required."),
  effective_date: yup
    .date("Enter a valid date")
    .required("Effective Date is required")
    .nullable(),
  expiring_date: yup
    .date("Enter a valid date")
    .required("Expiration Date is required")
    .min(
      yup.ref("effective_date"),
      "Expiration Date must be later than Effective Date"
    )
    .nullable(),
});

const AddEditTransactionTypeFeeModal = ({
  onClose,
  isOpen,
  onSubmit,
  slabToEdit,
  isEdit,
  type,
}) => {
  const [loading, setLoading] = useState(false);

  const addClickHandler = async (values) => {
    const data = {
      ...values,
      group: values?.group?.value,
      transaction_type: type,
      effective_date: formatDate(values?.effective_date, "yyyy-MM-dd"),
      expiring_date: formatDate(values?.expiring_date, "yyyy-MM-dd"),
    };
    // console.log(data);
    try {
      setLoading(true);
      const response = await merchantApi.post(TRANSACTION_TYPE_FEE, data);
      console.log(response);
      onSubmit();
      toast.success("Fee Added Successfully!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const editClickHandler = async (values) => {
    const data = {
      ...values,
      group: values?.group?.value,
      transaction_type: type,
      effective_date: formatDate(values?.effective_date, "yyyy-MM-dd"),
      expiring_date: formatDate(values?.expiring_date, "yyyy-MM-dd"),
    };
    try {
      setLoading(true);
      const response = await merchantApi.patch(
        `${TRANSACTION_TYPE_FEE}${slabToEdit?.id}/`,
        data
      );
      console.log(response);
      toast.success("Fee Updated Successfully!");
      onSubmit();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const fetchOptions = async (query) => {
    const url = query
      ? `${CUSTOMER_FEE_GROUP}?search=${query}`
      : `${CUSTOMER_FEE_GROUP}`; // No search parameter when query is empty

    const response = await merchantApi.get(url);
    return response?.data?.results?.map((item) => ({
      label: item?.name, // Adjust based on your API response
      value: item?.id,
    }));
  };

  const formik = useFormik({
    initialValues: {
      amount_to: "",
      amount_from: "",
      customer_fee_percentage: 0,
      customer_fee_fixed: 0,
      group: null,
      effective_date: null,
      expiring_date: null,
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      return isEdit ? editClickHandler(values) : addClickHandler(values);
    },
  });

  useEffect(() => {
    if (slabToEdit) {
      formik.setFieldValue("amount_to", slabToEdit?.amount_to);
      formik.setFieldValue("amount_from", slabToEdit?.amount_from);
      formik.setFieldValue(
        "customer_fee_percentage",
        slabToEdit?.customer_fee_percentage || ""
      );
      formik.setFieldValue(
        "customer_fee_fixed",
        slabToEdit?.customer_fee_fixed || ""
      );
      formik.setFieldValue("group", {
        label: slabToEdit?.group?.name,
        value: slabToEdit?.group?.id,
      });
      formik.setFieldValue(
        "effective_date",
        slabToEdit?.effective_date ? new Date(slabToEdit?.effective_date) : null
      );
      formik.setFieldValue(
        "expiring_date",
        slabToEdit?.expiring_date ? new Date(slabToEdit?.expiring_date) : null
      );
    }
  }, [slabToEdit]);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  function getTypeName(val) {
    if (val === "IncomingTransfer") {
      return "Incoming Transfer";
    } else if (val === "InternalTransfer") {
      return "Internal Transfer";
    } else if (val === "Remittance") {
      return "Outgoing Transfer";
    } else if (val === "SupplierPayment") {
      return "Customer Payment";
    } else {
      return "Contract Payment";
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"sm"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontSize: "22px !important" }}>
          {isEdit
            ? `Edit ${getTypeName(type)} Fee`
            : `Add New ${getTypeName(type)} Fee`}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              paddingTop: "20px !important",
              paddingBottom: "0 !important",
            }}
          >
            <Stack
              spacing={2}
              direction={"column"}
              alignItems={"center"}
              mb={1}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 1,
                  width: "100%",
                }}
              >
                <DatePicker
                  fullWidth
                  format={"dd/MM/yyyy"}
                  className="bg-white"
                  id={"date"}
                  name="effective_date"
                  value={formik.values.effective_date}
                  label="Effective Date*"
                  onChange={(date) => {
                    formik.setFieldTouched("effective_date", true);
                    formik.setFieldValue("effective_date", date);
                  }}
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                      error:
                        formik.touched.effective_date &&
                        Boolean(formik.errors.effective_date),
                      helperText:
                        formik.touched.effective_date &&
                        formik.errors.effective_date,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1a1f64", // default border color
                        borderWidth: "2px",
                      },
                    },
                  }}
                />
                <DatePicker
                  fullWidth
                  format={"dd/MM/yyyy"}
                  className="bg-white"
                  id={"date"}
                  name="expiring_date"
                  value={formik.values.expiring_date}
                  label="Expiration Date*"
                  onChange={(date) => {
                    formik.setFieldTouched("expiring_date", true);
                    formik.setFieldValue("expiring_date", date);
                  }}
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                      error:
                        formik.touched.expiring_date &&
                        Boolean(formik.errors.expiring_date),
                      helperText:
                        formik.touched.expiring_date &&
                        formik.errors.expiring_date,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1a1f64", // default border color
                        borderWidth: "2px",
                      },
                    },
                  }}
                />
              </Box>
              <SearchableSelectField
                label="Group"
                name="group"
                placeholder="Type to search"
                formik={formik}
                fetchOptions={fetchOptions}
                required
              />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 1,
                  width: "100%",
                }}
              >
                <TextFieldComponent
                  formik={formik}
                  name={"amount_from"}
                  type={"number"}
                  label={"Amount From"}
                  required
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"USD"}</InputAdornment>
                    ),
                  }}
                />
                <TextFieldComponent
                  formik={formik}
                  name={"amount_to"}
                  type={"number"}
                  label={"Amount To"}
                  required
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"USD"}</InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 1,
                  width: "100%",
                }}
              >
                <TextFieldComponent
                  formik={formik}
                  name={"customer_fee_percentage"}
                  type={"number"}
                  label={"Fee Percentage"}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"%"}</InputAdornment>
                    ),
                  }}
                />
                <TextFieldComponent
                  formik={formik}
                  name={"customer_fee_fixed"}
                  type={"number"}
                  label={"Fee Fixed"}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"USD"}</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={onClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                {isEdit ? "Update Fee" : "Add Fee"}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default AddEditTransactionTypeFeeModal;
