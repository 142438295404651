import {
  format,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";
import { formatDate } from "../../utils/common";
export const flow = [
  {
    name: "Any",
    value: "any",
  },
  {
    name: "Inward",
    value: "inward",
  },
  {
    name: "Outward",
    value: "outward",
  },
];
export const downloadFormats = [
  {
    name: "Excel",
    value: "excel",
  },
];
export const timePeriodOptions = [
  {
    name: "Daily",
    value: "daily",
  },
  {
    name: "Weekly",
    value: "weekly",
  },
  {
    name: "Monthly",
    value: "monthly",
  },
  {
    name: "Yearly",
    value: "yearly",
  },
];
export const reportTimePeriods = [
  {
    name: "This Week",
    key: "week",
  },
  {
    name: "This Month",
    key: "month",
  },
  {
    name: "This Quarter",
    key: "quarter",
  },
  {
    name: "This Year",
    key: "year",
  },
];

export const contractStatus = [
  {
    name: "Unsettled",
    value: "unsettled",
  },
  {
    name: "Partially settled",
    value: "partial_settled",
  },
  {
    name: "Settled",
    value: "settled",
  },
];

export const customerStatus = [
  {
    name: "Active",
    value: "active",
  },
  {
    name: "Draft",
    value: "draft",
  },
];

export const logTransactionType = [
  {
    name: "Debit",
    value: "debit",
  },
  {
    name: "Credit",
    value: "credit",
  },
];
